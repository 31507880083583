<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-etc-menu></admin-etc-menu>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    팝업등록/수정
                                </v-col>
                                <v-col cols="12" class="" md="12">

                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:70%"/>
                                        </colgroup>

                                        <tr>
                                            <th class="th">관리 ID</th>
                                            <td class="td">
                                                <input id="popId" v-model="popInfo.popId" readonly>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">제목</th>
                                            <td class="td">
                                                <input id="popTitle" v-model="popInfo.popTitle">

                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">사용여부</th>
                                            <td class="td">
                                                <input type="radio" id="Y" value="Y" v-model="popInfo.useYn"
                                                       style="width: 20px; height: 12px;">
                                                <label for="Y">사용</label>
                                                <input type="radio" id="N" value="N" v-model="popInfo.useYn"
                                                       style="width: 20px; height: 12px;">
                                                <label for="N">미사용</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">팝업방식</th>
                                            <td class="td">
                                                <input type="radio" id="popType_true" value="true"
                                                       v-model="popInfo.popType"
                                                       style="width: 20px; height: 12px;">
                                                <label for="popType_true">팝업창 방식</label>
                                                <input type="radio" id="popType_false" value="false"
                                                       v-model="popInfo.popType"
                                                       style="width: 20px; height: 12px;">
                                                <label for="popType_false">레이어 방식</label>
                                            </td>
                                        </tr>
                                        <!--<tr>
                                            <th class="th">팝업기간</th>
                                            <td class="td">
                                                <input id="popStartDate" v-model="popInfo.popStartDate"
                                                       style="width: 20%"  placeholder="yyyy-mm-dd">~
                                                <input id="popEndDate" v-model="popInfo.popEndDate"
                                                       style="width: 20%" placeholder="yyyy-mm-dd">
                                            </td>
                                        </tr>-->

                                        <tr>
                                            <th class="th">팝업기간</th>
                                            <td class="td">
                                                <input id="popStartDate" style="width: 150px;" v-model="popInfo.popStartDate" readonly>~
                                                <input id="popEndDate" style="width: 150px;" v-model="popInfo.popEndDate" readonly>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th class="th">팝업위치</th>
                                            <td class="td">
                                                왼쪽<input id="popLeft" v-model="popInfo.popLeft" style="width: 20%">
                                                상단<input id="popTop" v-model="popInfo.popTop" style="width: 20%">
                                                *숫자로 입력
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">팝업창크기</th>
                                            <td class="td">
                                                가로<input id="popWidth" v-model="popInfo.popWidth" style="width: 20%">
                                                세로<input id="popHeight" v-model="popInfo.popHeight" style="width: 20%">
                                                *숫자로 입력
                                            </td>
                                        </tr>

                                        <tr>
                                            <th class="th">팝업창 스크롤</th>
                                            <td class="td">
                                                <input type="radio" id="popScroll_true" value="true"
                                                       v-model="popInfo.popScroll"
                                                       style="width: 20px; height: 12px;">
                                                <label for="popScroll_true">스크롤 허용</label>
                                                <input type="radio" id="popScroll_false" value="false"
                                                       v-model="popInfo.popScroll"
                                                       style="width: 20px; height: 12px;">
                                                <label for="popScroll_false">스크롤 없음</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">링크주소</th>
                                            <td class="td">
                                                <input id="popLink" v-model="popInfo.popLink">

                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">링크방식</th>
                                            <td class="td">
                                                <input type="radio" id="popLinkType_0" value="0"
                                                       v-model="popInfo.popLinkType"
                                                       style="width: 20px; height: 12px;">
                                                <label for="popLinkType_0">새창으로 링크</label>
                                                <input type="radio" id="popLinkType_1" value="1"
                                                       v-model="popInfo.popLinkType"
                                                       style="width: 20px; height: 12px;">
                                                <label for="popLinkType_1">현재창으로 링크</label>
                                                <input type="radio" id="popLinkType_2" value="2"
                                                       v-model="popInfo.popLinkType"
                                                       style="width: 20px; height: 12px;">
                                                <label for="popLinkType_2">메인창으로 링크</label>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th class="th">자료등록방식</th>
                                            <td class="td">
                                                <input type="radio" id="popDataType_0" value="0"
                                                       v-model="popInfo.popDataType"
                                                       style="width: 20px; height: 12px;" @click="setDataType('0')">
                                                <label for="popDataType_0">이미지로 등록</label>
                                                <input type="radio" id="popDataType_1" value="1"
                                                       v-model="popInfo.popDataType"
                                                       style="width: 20px; height: 12px;" @click="setDataType('1')">
                                                <label for="popDataType_1">직접 편집</label>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>

                                <template v-if="popInfo.popDataType == 0">
                                    <!--<v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                        사진등록
                                    </v-col>-->
                                    <v-col cols="6">
                                        <input type="file" name="files" accept="image/*" @change="uploadImage"/>

                                        <v-card>
                                            <v-card-title class="text-h6">
                                                <v-img
                                                        :src="popInfo.popImg"
                                                        :lazy-src="popInfo.popImg"
                                                        class="grey lighten-2"

                                                ></v-img>
                                            </v-card-title>
                                            <v-card-subtitle>
                                                <div style="width:100%; height: 40px;">
                                                    {{popInfo.popImg}}
                                                    <div>
                                                        <v-btn
                                                                color="blue-grey"
                                                                class="mx-0 white&#45;&#45;text center-block"
                                                                style="color:#fff"
                                                                x-small
                                                                @click="rowClick"
                                                        >
                                                            이미지 삭제
                                                        </v-btn>
                                                    </div>

                                                </div>
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-col>
                                </template>
                                <template v-if="popInfo.popDataType == 1">
                                    <!--<v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                        직접편집
                                    </v-col>-->
                                    <v-col cols="12">
                                        <div class="editor-container" style="height: 250px;">
                                            <div id="editor">
                                            </div>
                                        </div>
                                    </v-col>
                                </template>

                                <v-col cols="12" class="my-8" md="12">
                                    <template v-if="!isMobile">
                                        <v-col cols="12" style="text-align: right;">
                                            <v-btn
                                                    color="error"
                                                    class="mx-4  center-block"
                                                    @click="btnClick(4)"
                                                    style="color: #fff; font-weight: bold; float: left;"
                                            >
                                                삭제
                                            </v-btn>
                                            <v-btn
                                                    color="primary"
                                                    class="mx-4 center-block"
                                                    @click="btnClick(1)"
                                                    style="color: #fff; font-weight: bold;"
                                            >
                                                저장
                                            </v-btn>
                                            <v-btn
                                                    color="#19CE60"
                                                    class="mx-4 center-block"
                                                    @click="btnClick(2)"
                                                    style="font-weight: bold; color:#fff;"
                                            >
                                                미리보기
                                            </v-btn>
                                            <v-btn
                                                    color=""
                                                    class="mx-4 center-block"
                                                    @click="btnClick(3)"
                                                    style="font-weight: bold;"
                                            >
                                                목록
                                            </v-btn>
                                        </v-col>
                                    </template>
                                    <template v-else>
                                        <v-col cols="12" class="mt-5">
                                            <v-btn
                                                    color="error"
                                                    class="mx-1"
                                                    @click="btnClick(4)"
                                                    style="color: #fff; font-weight: bold; float: left;"
                                            >
                                                삭제
                                            </v-btn>
                                            <v-btn
                                                    color="primary"
                                                    class="mx-1"
                                                    @click="btnClick(1)"
                                                    style="color: #fff; font-weight: bold;"
                                            >
                                                저장
                                            </v-btn>
                                            <v-btn
                                                    color=""
                                                    class="mx-1"
                                                    @click="btnClick(3)"
                                                    style="font-weight: bold;"
                                            >
                                                목록
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn
                                                    color="#19CE60"
                                                    class="mx-1"
                                                    @click="btnClick(2)"
                                                    style="font-weight: bold; color:#fff;"
                                            >
                                                미리보기
                                            </v-btn>

                                        </v-col>
                                    </template>


                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

        <div :id="'layerPop'+popInfo.popId" class="layerPop">
            <table>
                <tr>
                    <td>
                        <table>
                            <tr>
                                <td>
                                    <template v-if="popInfo.popDataType == 0">
                                        <div class="layerBody">
                                            <v-img
                                                    :src="popInfo.popImg"
                                                    :lazy-src="popInfo.popImg"
                                                    class="grey lighten-2"

                                            ></v-img>
                                        </div>
                                    </template>
                                    <template v-else-if="popInfo.popDataType ==1">
                                        <div id="" class="cont">
                                            <div class="ql-editor">
                                                <span v-html="popInfo.popContent"></span>
                                            </div>
                                        </div>
                                    </template>
                                </td>
                            </tr>
                            <tr style="background-color: #eaeaea" align="right" class="layerBottom">
                                <input type="checkbox" :id="'check'+popInfo.popId" value="Y">
                                <span class="mx-3">하루동안 창을 표시하지 않음</span>
                                <!--<a href="#" @click="closeLayer('layerPop')" class="mx-3">닫기</a>-->
                                <a href="#" @click="closeWin(popInfo.popId)" class="mx-3">닫기</a>

                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
    import AdminEtcMenu from '../../components/AdminEtcMenu'
    export default {
        name: 'AdminPopInfo',
        components: {
            AdminEtcMenu
        },
        data: () => ({
            isCheck: false,

            popId: "",
            popList: [],
            popInfo: {
                //popId: "",
                popType: "true",
                popTitle: "",
                popContent: "",
                popImg: "",
                popStartDate: "",
                popEndDate: "",
                popTop: "0",
                popLeft: "0",
                popWidth: "0",
                popHeight: "0",
                popScroll: "false",
                popDataType: "0",
                popLink: "",
                popLinkType: "0",
                useYn: "Y",

            },
            popImgList: [],
            popImgInfo: {},
            popImgRoute_tmp: "",
            quill: null,
            isMobile: false,
        }),
        created: function () {
            // 모바일 체크
            this.isMobile = this.$isMobile();

            // 맨위로
            if (this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            this.popId = this.$getParameterByName('popId');

            if (this.popId === "") {
                //alert("객실 ID가 없습니다. 다시 시도해주세요.");
                //window.history.back();
            } else {
                this.getPopInfo();
            }

            this.getCookie();
            this.setDatePicker();


        },
        mounted() {
            this.$nextTick(() => {
                let _this = this;
                let options = {
                    modules: {
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'],
                                [{'align': []}, {'size': ['small', false, 'large', 'huge']}],
                                [{'color': []}, {'background': []}],
                                ['image'],
                            ],
                        },
                    },
                    placeholder: '내용을 입력해 주세요.',
                    theme: 'snow',
                };
                if (this.popInfo.popDataType === 1) {
                    this.quill = new Quill('#editor', options);
                    this.quill.getModule('toolbar').addHandler('image', function () {
                        _this.changeImage();
                    });
                }
            })
        },
        methods: {
            btnClick(val) {

                switch (val) {
                    case 1 : {
                        this.savePopInfo();
                        break;
                    }
                    case 2: {

                        if (this.popInfo.popType === 'true') {

                            let options = 'top=' + this.popInfo.popTop +
                                ', left=' + this.popInfo.popLeft +
                                ', width=' + this.popInfo.popWidth +
                                ', height=' + this.popInfo.popHeight +
                                ', status=no, menubar=no, toolbar=no, resizable=no';
                            let route = '/popup/read?popId=' + this.popInfo.popId;
                            let popNm = "팝업" + this.popInfo.popId;
                            window.open(route, popNm, options);

                        } else {
                            this.$nextTick(() => {
                                var pop = document.getElementById('layerPop'+this.popInfo.popId);
                                var pop_body = document.getElementById('bodyPop');

                                pop.style.display = "table";
                                pop.style.top = this.popInfo.popTop + "px";
                                pop.style.left = this.popInfo.popLeft + "px";


                                if (this.popInfo.popDataType === '0') {
                                    pop.style.width = this.popInfo.popWidth + "px";
                                    pop.style.height = Number(this.popInfo.popHeight) + 30 + "px";
                                } else {
                                    pop.style.minwidth = this.popInfo.popWidth + "px";
                                    pop.style.minheight = Number(this.popInfo.popHeight) + 30 + "px";

                                    //pop.style.width = this.popInfo.popWidth + "px";
                                    //pop.style.height = Number(this.popInfo.popHeight) + 30 + "px";
                                }


                                if (this.popInfo.popScroll === 'true') {
                                    pop.style.overflow = 'auto';
                                } else {
                                    pop.style.overflow = 'hidden';
                                }


                                //pop_body.width = this.popInfo.popWidth + "px";
                                //pop_body.height = this.popInfo.popHeight + "px";

                                $("#layerPop").fadeIn(400);
                            });
                        }


                        break;
                    }
                    case 3: {
                        this.$router.push({path: "/admin/pop/list"});
                        break;
                    }
                    case 4:{
                        if (!confirm("팝업정보를 삭제 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {

                            let formdata = {
                                popId : this.popInfo.popId
                            };

                            return this.$store.dispatch("pop/deletePop", formdata)
                                .then((resp) => {
                                    setTimeout(() => {
                                        //alert("정보를 삭제 했습니다.");
                                        alert(resp.message);
                                        //close();
                                        //this.getList();
                                        this.$router.push({path: "/admin/pop/list"});

                                    }, 300)
                                })
                                .catch((err) => {
                                })

                        }
                        break;
                    }

                }

            },
            closeLayer(IdName) {
                var pop = document.getElementById(IdName);
                pop.style.display = "none";
            },
            savePopInfo() {

                this.popInfo.popStartDate = $('#popStartDate').val();
                this.popInfo.popEndDate = $('#popEndDate').val();

                switch (this.popInfo.popDataType) {
                    case "0" : {  // 이미지


                        break;
                    }
                    case "1" : { // 직접편집

                        if (this.quill != null) {

                            this.popInfo.popContent = this.quill.root.innerHTML;

                        }

                        break;
                    }
                }

                return this.$store.dispatch("pop/updatePop", this.popInfo)
                    .then((resp) => {
                        setTimeout(() => {
                            alert("정보를 저장 했습니다.");


                            this.popId = resp.message.popId;
                            location.href = "/admin/pop/info" + '?popId=' + resp.message.popId;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },

            getPopInfo() {

                let fdata = {
                    popId: this.popId
                };

                return this.$store.dispatch("pop/getPopInfo", fdata)
                    .then((resp) => {
                        setTimeout(() => {


                            this.popInfo = resp.message;

                            this.setDataType(this.popInfo.popDataType)

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            uploadImage(e) {
                let url = '/file/upload.do';

                let formData = new FormData();
                formData.append('file', e.target.files[0]);

                let header = {
                    headers: {'Content-Type': 'multipart/form-data'}
                };

                return axios.post(url, formData, header).then(resp => {

                    if (resp.data) {

                        this.popInfo.popImg = resp.data;

                        return resp.data
                    }

                    throw new Error('Server or network error');
                });
            },

            menuClick(route, popGubun) {
                if (popGubun != null) {
                    location.href = route + '?popGubun=' + popGubun;
                } else {
                    location.href = route;
                }
            },

            changeImage: function (e) {
                let _this = this;

                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.click();

                input.onchange = function () {

                    const fd = new FormData();
                    const file = $(this)[0].files[0];

                    fd.append('file', file);

                    let url = '/file/upload.do';

                    let header = {
                        headers: {'Content-Type': 'multipart/form-data'}
                    };

                    return axios.post(url, fd, header).then(resp => {
                        if (resp.data) {

                            const range = _this.quill.getSelection();
                            //_this.quill.insertEmbed(range.index, 'image', 'http://localhost:8082/file/'+resp.data);
                            _this.quill.insertEmbed(range.index, 'image', resp.data);
                            return resp.data
                        }
                        throw new Error('Server or network error');
                    });
                }
            },
            rowClick() {


                if (!confirm("사진을 삭제 하시겠습니까?")) {
                    // 취소(아니오) 버튼 클릭 시 이벤트
                } else {
                    // 확인(예) 버튼 클릭 시 이벤트
                    this.popInfo.popImg = "";
                }
            },
            setDataType(val) {

                this.$nextTick(() => {
                    if (this.quill != null) {
                        let cont = this.quill.root.innerHTML;
                        this.popInfo.popContent = cont;
                    }


                    let _this = this;
                    setTimeout(() => {

                        switch (val) {
                            case "0" : {     // 그림

                                break;
                            }
                            case "1" : {     // 직접편집
                                let options = {
                                    modules: {
                                        toolbar: {
                                            container: [
                                                ['bold', 'italic', 'underline', 'strike'],
                                                [{'align': []}, {'size': ['small', false, 'large', 'huge']}],
                                                [{'color': []}, {'background': []}],
                                                ['image'],
                                            ],
                                        },

                                    },
                                    placeholder: '내용을 입력해 주세요.',
                                    theme: 'snow',

                                };

                                this.quill = new Quill('#editor', options);
                                this.quill.getModule('toolbar').addHandler('image', function () {
                                    _this.changeImage();
                                });

                                if (this.popInfo.popContent !== undefined) {
                                    this.quill.root.innerHTML = this.popInfo.popContent;
                                }

                                //this.popImgList = this.popInfo.popImg;

                                break;
                            }

                        }


                    }, 300)


                })
            },
            setCookie(cname, cvalue, exdays) {
                var todayDate = new Date();
                todayDate.setTime(todayDate.getTime() + (exdays*24*60*60*1000));
                var expires = "expires=" + todayDate.toUTCString(); // UTC기준의 시간에 exdays인자로 받은 값에 의해서 cookie가 설정 됩니다.
                document.cookie = cname + "=" + cvalue + "; " + expires;
            },

            getCookie (cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');

                for(var i=0; i<ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0)==' ') c = c.substring(1);
                    if (c.indexOf(name) != -1) return c.substring(name.length,c.length);
                }
                return "";
            },
            closeWin(key) {
                if ($("#check"+key).prop("checked")) {

                }else{

                }
                this.setCookie('close' + key, 'Y', 7);
                $("#layerPop" + key + "").hide();

            },
            setDatePicker() {
                this.$nextTick(function () {
                    let _this = this;

                    let todayYear = this.$getTodayYear()+3;

                    $('#popStartDate').daterangepicker({
                        "locale": {
                            "format": "YYYY-MM-DD",
                            "separator": " ~ ",
                            "applyLabel": "확인",
                            "cancelLabel": "취소",
                            "daysOfWeek": ["일", "월", "화", "수", "목", "금", "토"],
                            "monthNames": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
                        }, singleDatePicker: true,
                        showDropdowns: true,
                        minYear:2020,
                        maxYear:todayYear,
                    });

                    $('#popEndDate').daterangepicker({
                        "locale": {
                            "format": "YYYY-MM-DD",
                            "separator": " ~ ",
                            "applyLabel": "확인",
                            "cancelLabel": "취소",
                            "daysOfWeek": ["일", "월", "화", "수", "목", "금", "토"],
                            "monthNames": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
                        }, singleDatePicker: true,
                        showDropdowns: true,
                        minYear:2020,
                        maxYear:todayYear,
                    });

                    $('#popStartDate').val('');
                    $('#popEndDate').val('');


                })
            }

        },

    }
</script>

<style>

</style>

